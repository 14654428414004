import imgT1 from "../images/maskid.jpg"
import Curriculum from "../images/km-curriculum.jpeg"
import imgT3 from "../images/km-platform.jpg"
import Yb from "../images/youngbroadway.jpeg"
import imgT5 from "../images/i360.jpg"
import kmMockup from "../images/km/km-mockup.jpg"
import HCTV from "../images/hctv.jpg"
import Mockup from "../images/sfds.jpg"

export const projectdata = [
  {
    brand: `Work`,
    slug: `stfparishschool`,
    title: `St. Francis de Sales Parish School`,
    categories: ["web", `ux-design`],
    thumbnail: Mockup,
    link: "/projects/stfparishschool",
  },
  {
    brand: `Academic`,
    slug: `hctv`,
    title: `Streaming Platform for Hudson Community TV`,
    categories: ["web", `ux-design`, "academic"],
    thumbnail: HCTV,
    link: "/projects/hctv",
  },
  {
    brand: `Academic`,
    slug: `maskid`,
    title: `Face Mask Identification`,
    categories: ["web", `AI`, "academic"],
    thumbnail: imgT1,
    link: "/projects/maskid",
  },
  {
    brand: `Insight360`,
    slug: `i360`,
    title: `Insight360 Website and Re-Branding`,
    categories: [`web`, `branding`],
    thumbnail: imgT5,
    link: "/projects/i360",
  },
  {
    brand: `KaleidoscopeME`,
    slug: `kaleidoscopeme`,
    title: `Edutainment Startup's Web Presence`,
    categories: [`web`, `ux-design`],
    thumbnail: kmMockup,
    link: "/projects/kaleidoscopeme",
  },
  {
    brand: `KaleidoscopeME`,
    slug: `km-curriculum`,
    title: `Curriculum Delivery Platform`,
    categories: [`web`, `ux-design`],
    thumbnail: Curriculum,
    link: "https://curriculum.ethanh.works",
  },
  {
    brand: `KaleidoscopeME`,
    slug: `km-platform`,
    title: `Subscription Platform for SEL Content`,
    categories: [`web`, `ux-design`],
    thumbnail: imgT3,
    link: "https://km-platform.ethanh.works",
  },
  {
    brand: `Academic`,
    slug: `youngbroadway`,
    title: `Platform to Make Broadway More Accessible to Children`,
    categories: [`web`, `branding`, "academic"],
    thumbnail: Yb,
    link: "https://youngbroadway.ethanh.works/",
  },
]
