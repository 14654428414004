import * as React from "react"


export default function Footer() {
  return (
    <footer className="bg-gray-100" style={{
        padding: `1.5rem 5vw`,
        maxWidth: `100vw`,
      }}>
        © {new Date().getFullYear()} Ethan Hancock
      </footer>
  )
}
