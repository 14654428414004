import * as React from "react"
import { Link } from "gatsby"
import "../styles/home.css"

const ProjectCard = ({ workItem, link }) => {
  return (
    <div className="group project-card">
      <Link to={link}>
        <div className="transition duration-150 ease-in-out rounded-lg group-hover:shadow-2xl">
          <img src={workItem.thumbnail} alt="" className="rounded-lg" />
        </div>
      </Link>
      <div className="mt-4">
        <div className="flex mb-4">
          {workItem.categories.map(i => (
            <p className="px-4 py-1 mr-2 text-xs font-bold text-white bg-gray-400 rounded-full">
              {i}
            </p>
          ))}
        </div>
        <Link
          to={link}
          target="_blank"
          className="text-lg font-semibold project-link ethan-hover"
        >
          {workItem.title}{" "}
        </Link>
      </div>
    </div>
  )
}

export default ProjectCard
