import React, { useState, useEffect } from "react"
import ProjectCard from "./projectCard"
import { projectdata } from "../data/projectdata"

const ProjectGrid = () => {
  const [items, setItems] = useState([])
  const [activeLink, setActiveLink] = useState("*")

  useEffect(() => {
    setItems(projectdata)
  }, [])

  const filterBy = cat => {
    if (cat === "*") {
      setActiveLink("*")
      setItems(projectdata)
    } else {
      const filteredItems = projectdata.filter(item => {
        return item.categories.indexOf(cat) !== -1
      })
      setActiveLink(cat)
      setItems(filteredItems)
    }
  }

  return (
    <>
      {/* <!-- Works Area --> */}
      <div>
        <div className="flex space-x-8 mb-16">
          <li className="list-none ethan-hover">
            <button
              className={`nav-link font-bold text-uppercase ${
                activeLink === "*" ? "highlight px-1" : null
              }`}
              onClick={e => {
                e.preventDefault()
                filterBy("*")
              }}
            >
              All works
            </button>
          </li>
          <li className="list-none ethan-hover">
            <button
              className={`nav-link font-bold text-uppercase ${
                activeLink === "branding" ? "highlight px-1" : null
              }`}
              onClick={e => {
                e.preventDefault()
                filterBy("branding")
              }}
            >
              Branding
            </button>
          </li>
          <li className="list-none ethan-hover">
            <button
              className={`nav-link font-bold text-uppercase ${
                activeLink === "ux-design" ? "highlight px-1" : null
              }`}
              onClick={e => {
                e.preventDefault()
                filterBy("ux-design")
              }}
            >
              UX Design
            </button>
          </li>
          <li className="list-none ethan-hover">
            <button
              className={`nav-link font-bold text-uppercase ${
                activeLink === "web" ? "highlight px-1" : null
              }`}
              onClick={e => {
                e.preventDefault()
                filterBy("web")
              }}
            >
              Web
            </button>
          </li>
        </div>

        <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
          {items.map((item, index) => (
            <ProjectCard workItem={item} mb="30px" link={item.link} />
          ))}
        </div>
      </div>
    </>
  )
}

export default ProjectGrid
