import * as React from "react"
import Header from "../components/header"
import Particles from "tsparticles"
import particlesConfig from "../components/particlesConfig.json"
import ProjectGrid from "../components/project-grid"
import Footer from "../components/footer"

import "../styles/home.css"
// markup
const IndexPage = () => {
  return (
    <div
      style={{
        margin: `0 auto`,
        maxWidth: `100vw`,
      }}
    >
      {/* <Particles
        params={particlesConfig}
        className="home-particles__container"
      /> */}
      <div className="bg-transparent home-cta">
        <Header></Header>
        <div className="mx-auto ethan-width my-36">
          <h1 className="text-6xl font-black text-center text-black">
            Hi, I'm <span className="highlight px-0.5">Ethan Hancock</span>
          </h1>
          <p className="max-w-2xl mx-auto my-4 text-center">
            I am a passionate advocate for technology, education and design
            being embedded in our everyday lives. With experience working for
            government agencies to startups I am ready to take on new, exciting
            and innovative challenges.
          </p>
        </div>
      </div>
      <main className="ethan-width" style={{ padding: `0 1.0875rem 1.45rem` }}>
        <ProjectGrid></ProjectGrid>
      </main>
      <Footer />
    </div>
  )
}

export default IndexPage
